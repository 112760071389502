'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { historyAtom } from '@/src/stores/history';

export default function HistoryProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const searchParams = new URLSearchParams(useSearchParams());
  const setHistory = useSetAtom(historyAtom);

  useEffect(() => {
    if (!pathname) return;

    setHistory(prev => {
      if (prev[prev.length - 1]?.path !== pathname || prev[prev.length - 1]?.params !== searchParams.toString()) {
        return [...prev, { path: pathname, params: searchParams.toString() }];
      }
      return prev;
    });
  }, [pathname, searchParams, setHistory]);

  return children;
}
