import { InputAdornment, TextField } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './Input.module.scss';
import Button, { ButtonVariants } from '../Button/Button';
import Svg from '../Svg';

type InputProps = {
  value: string | number,
  setValue?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onKeyDown?: (e: React.KeyboardEvent) => void,
  onFocus?: (e: React.FocusEvent) => void,
  type?: string,
  id: string,
  label?: string,
  required?: boolean,
  fullWidth?: boolean,
  errorMessage?: string,
  infoText?: string,
  disabled?: boolean,
  className?: string,
  size?: 'small' | 'medium',
  name?: string,
  placeholder?: string,
  icon?: React.ReactNode,
  iconSize?: number,
  multiline?: boolean,
  rowsCount?: { min?: number, max?: number },
  labelProps?: Omit<React.HTMLAttributes<HTMLLabelElement>, 'color'>,
  inputProps?: React.InputHTMLAttributes<HTMLInputElement> & {
    ref: React.Ref<HTMLInputElement>,
  },
  customContent?: React.ReactElement,
};

const Input = ({
  value,
  setValue,
  onBlur,
  onKeyDown,
  type = 'text',
  id,
  label,
  required = false,
  fullWidth = false,
  disabled = false,
  className,
  size,
  name,
  errorMessage,
  infoText,
  onFocus,
  placeholder,
  icon,
  iconSize = 16,
  multiline,
  rowsCount,
  labelProps,
  inputProps,
  customContent,
}: InputProps) => {
  const [inputHasAutoFilledValue, setInputHasAutoFilledValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isPasswordInput = type === 'password';
  const passwordInputType = showPassword ? 'text' : 'password';

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const makeAnimationStartHandler = (e: React.AnimationEvent<HTMLDivElement>) => {
    const autoFilled = !!(e.target as Element)?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill' || e.animationName === 'mui-auto-fill-cancel') {
      setInputHasAutoFilledValue(autoFilled);
    }
  };

  return (
    <div>
      <div className={styles.inputContainer}>
        <TextField
          value={customContent ? '' : value}
          type={isPasswordInput ? passwordInputType : type}
          onChange={setValue}
          onBlur={e => {
            onBlur?.(e);
            setIsFocused(false);
          }}
          error={!!errorMessage}
          disabled={disabled}
          className={classNames(styles.input, className, {
            'field-error': errorMessage,
          })}
          id={id}
          label={required ? `${label}*` : label}
          variant='outlined'
          fullWidth={fullWidth}
          size={size}
          name={name}
          onKeyDown={onKeyDown}
          onFocus={e => {
            onFocus?.(e);
            setIsFocused(true);
          }}
          placeholder={placeholder}
          multiline={multiline}
          maxRows={rowsCount?.max}
          minRows={rowsCount?.min}
          slotProps={{
            inputLabel: {
              ...labelProps,
              shrink: isFocused || !!value || inputHasAutoFilledValue,
            },
            input: {
              inputProps,
              onAnimationStart: makeAnimationStartHandler,
              startAdornment: customContent ? (
                <InputAdornment position='start'>
                  {customContent}
                </InputAdornment>
              ) : undefined,
              endAdornment: isPasswordInput ? (
                <InputAdornment position='end'>
                  <Button
                    ariaLabel='toggle password visibility'
                    icon={showPassword ? <Svg name='eyeOpen' /> : <Svg name='eyeClose' />}
                    variant={ButtonVariants.ICON}
                    onClick={handleClickShowPassword}
                  />
                </InputAdornment>
              ) : (icon && (
                <div
                  className={styles.icon}
                  style={{
                    width: iconSize,
                    height: iconSize,
                  }}
                >
                  {icon}
                </div>
              )),
            },
          }}
        />
      </div>
      {errorMessage ? <p className={styles.error}>{errorMessage}</p> : infoText && <p className={styles.infoText}>{infoText}</p>}
    </div>
  );
};

export default Input;
